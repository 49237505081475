<template>
    <!-- <div class="bg-no-repeat bg-center bg-cover" :style="{ backgroundImage: `url(${forest_background})` }"> -->
    <div class="bg-darkpurple">
        <div class="flex flex-wrap flex-row gap-8 justify-center p-8 mx-auto max-w-screen-lg">
            <div v-if="isDataLoaded" class="bg-primarybackground rounded-3xl text-primarytext p-4 h-fit border-solid border-2 border-primarytext">
                <div class="px-4 md:px-8 pt-4 md:pt-8 pb-2">
                    <h1 class="text-3xl font-bold text-left pb-0">Education</h1>
                    <div class="text-left px-1 md:px-2">
                        <hr class="dynamicpinkpurple-line" />
                        <div>
                            <div class="font-bold text-lg">Ph.D. in Operations Research (Applied Mathematics)</div>
                            <div>Polytechnique Montréal, Montréal, QC | Aug 2024</div>
                            Focus on Combinatorial Optimization
                        </div>
                        <hr class="dynamicpinkpurple-line" />
                        <div>
                            <div class="font-bold text-lg">B.S. in Business Administration</div>
                            <div class="">Carnegie Mellon University, Pittsburgh, PA | Dec 2017</div>
                            Finance Concentration
                        </div>
                        <hr class="dynamicpinkpurple-line" />
                        <div>
                            <div class="font-bold text-lg">B.A. in Production and Technology Management (Theater Management)</div>
                            <div class="">Carnegie Mellon University, Pittsburgh, PA | May 2016</div>
                            Technical Direction Concentration
                        </div>
                        <hr class="dynamicpinkpurple-line"/>
                    </div>
                </div>

                <hr class="rainbow-line py-1 rounded-full my-8"/>

                <div class="px-4 md:px-8 pb-2">
                    <h1 class="text-3xl font-bold text-left pb-0 pt-0">Awards</h1>
                    <div class="text-left px-1 md:px-2">
                        <hr class="dynamicpinkpurple-line" />
                        <div>
                            <div class="font-bold text-lg">Best Paper | Aug 2022</div>
                            <div>Issued by the Association of Constraint Programming</div>
                            Award Received for the paper: "Peel-and-Bound: Generating Stronger Relaxed Bounds with Multivalued Decision Diagrams"
                        </div>
                        <hr class="dynamicpinkpurple-line" />
                        <div>
                            <div class="font-bold text-lg">College of Fine Arts Honors | May 2017</div>
                            <div class="">Issued by Carnegie Mellon University</div>
                            Graduated with honors from the Carnegie Mellon University School of Fine Arts
                        </div>
                        <hr class="dynamicpinkpurple-line"/>
                    </div>
                </div>

                <hr class="rainbow-line py-1 rounded-full my-8"/>


                <h1 class="text-3xl font-bold pl-4 md:pl-8 text-left pb-0 pt-2">Work Experience</h1>
                <hr class="pinkpurple-line mt-4"/>
                <div v-for="(job, index) in resume" :key="job.id">
                    <ExperienceCard :job="job"/>
                    <hr v-if="index < resume.length - 1" class="pinkpurple-line" /> <!-- Add this line -->
                </div>
                <hr class="pinkpurple-line mb-8"/>
            </div>
        </div>
     </div>
</template>

<script>
import { ref, onMounted, require } from 'vue';
import resumeData from "@/data/work_history.json";
import ExperienceCard from '@/components/ExperienceCard.vue'

export default {
    name: 'ResumeView',
    components: {
        ExperienceCard,
    },
    data() {
        return {
            forest_background:  require('@/../public/images/backgrounds/paper_quill_3.png'),
        };
    },
    setup(){
        const isDataLoaded = ref(false);
        const resume = ref([]);

        const processResumeData = async () => {
            resumeData.work_history.map(async (work) => {

                resume.value = [...resume.value, work];
            });
            resume.value = resume.value.reverse();
            isDataLoaded.value = true;
        };

        onMounted(() => {
            processResumeData();
        });

        return {resume, isDataLoaded};
    },
    methods: {
        formatWorkDescription(work) {
            return work.replace(/\n/g, '<br>');
        }
    }
}
</script>

<style>

    .rainbow-line {
        border: none;
        height: 2px;
        background: linear-gradient(to right, 
        #6b21a8, #9333ea, #6b21a8); /* Adjust the colors and order as needed */
        margin: 16px 0;
        width: calc(100%-2rem);
        margin-left: 1rem;
    }

    .pinkpurple-line {
        border: none;
        height: 2px;
        background: linear-gradient(to right,#c026d3, #6b21a8); /* Adjust the colors and order as needed */
        margin: 32px 0;
        width: calc(100% - 4rem);
        margin-left: 2rem;
    }

    .dynamicpinkpurple-line {
        border: none;
        height: 2px;
        background: linear-gradient(to right,#c026d3, #6b21a8); /* Adjust the colors and order as needed */
        margin: 16px 0;
        width: calc(100%);
    }


</style>