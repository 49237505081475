<template>
    <!-- <div class="bg-no-repeat bg-center bg-cover" :style="{ backgroundImage: `url(${forest_background})` }"> -->
    <div class="bg-darkpurple">
        <div class="flex flex-wrap flex-row gap-8 justify-center p-8 mx-auto max-w-screen-lg">
            <div v-if="isDataLoaded" class="bg-primarybackground rounded-3xl text-primarytext p-4 h-fit border-solid border-2 border-primarytext">
                <div class="p-4 md:p-8">
                    <h1 class="text-2xl font-bold text-left">Overview</h1>
                    <div class="text-left px-1 md:px-2 text-lg">
                        I specialize in Operations Research, which is the application of optimization techniques to improve decision-making in complex environments.
                        Basically - I use math to figure out the most efficient and equitable way to allocate resources, and enable people to make more informed choices.
                        My approach begins with in-depth discussions and interviews with key stakeholders to ensure a comprehensive understanding of the human and organizational needs at play.
                        After gathering these insights, I translate them into solvable mathematical models. 
                        Building this bridge between real-world complexities and mathematical models is a challenge I excel at.
                    </div>
                </div>

                <hr class="rainbow-line py-1 rounded-full mb-8 mt-4 md:mt-0"/>


                <h1 class="text-2xl font-bold pl-4 md:pl-8 text-left pb-0 pt-2">Project History</h1>
                <hr class="pinkpurple-line mt-4"/>
                <div v-for="(project, index) in projects" :key="project.id">
                    <ProjectCard :project="project"/>
                    <hr v-if="index < projects.length-1 " class="pinkpurple-line" /> <!-- Add this line -->
                </div>
                <hr class="pinkpurple-line mb-8"/>
            </div>
        </div>
     </div>
</template>

<script>
import { ref, onMounted, require } from 'vue';
import projectData from "@/data/project_history.json";
import ProjectCard from '@/components/ProjectCard.vue'

export default {
    name: 'ProjectView',
    components: {
        ProjectCard,
    },
    setup(){
        const isDataLoaded = ref(false);
        const projects = ref([]);

        const processProjectData = async () => {
            projectData.project_history.map(async (work) => {

                projects.value = [...projects.value, work];
            });
            // projects.value = projects.value.reverse();
            isDataLoaded.value = true;
        };

        onMounted(() => {
            processProjectData();
        });

        return {projects, isDataLoaded};
    },
    methods: {
    }
}
</script>

<style>

    .rainbow-line {
        border: none;
        height: 2px;
        background: linear-gradient(to right, 
        #6b21a8, #9333ea, #6b21a8); /* Adjust the colors and order as needed */
        margin: 16px 0;
        width: calc(100%-2rem);
        margin-left: 1rem;
    }

    .pinkpurple-line {
        border: none;
        height: 2px;
        background: linear-gradient(to right,#c026d3, #6b21a8); /* Adjust the colors and order as needed */
        margin: 32px 0;
        width: calc(100% - 4rem);
        margin-left: 2rem;
    }

    .dynamicpinkpurple-line {
        border: none;
        height: 2px;
        background: linear-gradient(to right,#c026d3, #6b21a8); /* Adjust the colors and order as needed */
        margin: 16px 0;
        width: calc(100%);
    }


</style>