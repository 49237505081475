<template>
    <div class="bg-darkpurple h-full">
        <div v-if="!passwordVisible">
            <div class="bg-verydarkpurple text-primarytext text-center py-4 border-b-2 border-t-2 border-primarytext">
                <p>This page will be updated from time to time. As the number of pictures grows, they will be categorized by date.</p>
            </div>

            <div class="" >
            <div 
                class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-8 m-8"  
                v-for="(group, groupindex) in groupedImages"
                :key="groupindex"
            >
                    <button
                        @click="loadImageGroup(groupindex)"
                        class="px-4 py-2 bg-purple-500 text-white rounded hover:bg-purple-600 font-extrabold w-full"
                    >
                    <span class="">
                        {{ `${formatDate(group.startDate)} - ${formatDate(group.endDate)}` }}
                        &nbsp;&nbsp;&nbsp;&nbsp; <span v-if="!usedChevrons.includes(groupindex)">&#9654;</span>  <span v-if="usedChevrons.includes(groupindex)">&#9660;</span> 
                    </span>
                    </button>

                    <div 
                        v-if="usedChevrons.includes(groupindex)"
                        v-for="(img, index) in pictures[groupindex]" 
                        :key="index" 
                        class="flex items-center justify-center">
                        <img
                            :src="img" 
                            :alt="img.split('/').pop()"  
                            class="object-cover rounded-3xl w-full h-auto"
                        >
                    </div>
            </div>
            </div>
        </div>

        <div class="mx-auto p-4 h-screen flex flex-col items-center justify-center" v-if="passwordVisible">
            <input
                v-model="password"
                type="password"
                placeholder="Enter the super secret password"
                class="border p-2 rounded w-full mb-4 max-w-xs text-center"
                :class="['text-center p-2 border rounded', shake ? 'animate-shake' : '', loading ? 'bg-gray-300 cursor-not-allowed' : '']"
                @keydown.enter="handleFetchCredentials"
                :disabled="loading"
            >

            <button 
                class="bottom-2 right-2 px-3 py-1 bg-purple-600 text-white text-sm rounded shadow hover:bg-purple-700 active:bg-purple-800 focus:outline-none transition duration-150 ease-in-out" 
                @click="handleFetchCredentials" 
                :disabled="loading"
                :class="{ 'bg-gray-300 hover:bg-gray-700 cursor-not-allowed': loading }"
            >
                Submit
            </button>

            
        </div>
    </div>
</template>
<script>
import { ref, onMounted, require, watch} from 'vue';
import { S3Client, ListObjectsV2Command, GetObjectCommand } from '@aws-sdk/client-s3';
import { use } from 'marked';

export default {
    name: 'EzraView',
    setup() {
        // State variables
        const password = ref(''); // To store user input password
        const credentials = ref(null); // To store fetched credentials
        const pictures = ref([]);
        const imageFilenames = ref([]);
        const loading = ref(false);
        const loadedIndexes = ref([]);
        const usedChevrons = ref([]);
        credentials.value = {}

        const passwordVisible = ref(true);
        const shake = ref(false);

        // Function to fetch credentials from Lambda
        const fetchCredentials = async (password) => {
            const endpoint = 'https://yp7rpq3hz4l6qpnj4gpvuxj43a0jkfwe.lambda-url.us-east-1.on.aws/';
            const body = { password };

            try {
                const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Origin': 'https://www.isaacrudich.com/'
                },
                body: JSON.stringify(body),
                });

                if (!response.ok) {
                throw new Error(`Error: ${response.status} ${response.statusText}`);
                }

                const data = await response.json();

                // Extract and store credentials
                credentials.value = {
                    accessKeyId: data.accessKeyId,
                    secretAccessKey: data.secretAccessKey,
                    sessionToken: data.sessionToken,
                    expiration: new Date(data.expiration),
                };
                return response
            } catch (error) {
                return response
            }
        };

        // Function to handle button click
        const handleFetchCredentials = async () => {
            loading.value = true;
            // Call fetchCredentials with the password entered by the user
            try {
                const response = await fetchCredentials(password.value);

                if (response.status === 200) {
                    passwordVisible.value = false;
                } else {
                    shake.value = true;
                    password.value = '';

                    // Remove shake animation after it's done (e.g., 500ms)
                    setTimeout(() => {
                        shake.value = false;
                    }, 500);
                }
            } catch (error) {
                shake.value = true;
                password.value = '';

                // Remove shake animation after it's done (e.g., 500ms)
                setTimeout(() => {
                    shake.value = false;
                }, 500);
                // console.log("Bad Credentials: ", credentials.value)
                console.error('Invalid credentials:', error);
            } finally {
                loading.value = false;
            }
        };

    

        // Reactive state for storing the list of files
        const s3Files = ref([]);
        
        // Initialize the S3 client
        const s3Client = new S3Client({
            region: 'us-east-1', // e.g., 'us-west-2'
            credentials: {
                accessKeyId: credentials.value.accessKeyId,
                secretAccessKey: credentials.value.secretAccessKey,
                sessionToken: credentials.value.sessionToken,
            },
        });

        // Watch for changes in credentials and initialize S3 client
        watch(credentials, (newCredentials) => {
            // Ensure the credentials are properly set
            if (newCredentials.accessKeyId && newCredentials.secretAccessKey) {
                    s3Client.value = new S3Client({
                    region: 'us-east-1',
                    credentials: {
                        accessKeyId: newCredentials.accessKeyId,
                        secretAccessKey: newCredentials.secretAccessKey,
                        sessionToken: newCredentials.sessionToken, // Add only if needed
                    },
                });

                // Now that the client is initialized, list files
                loadImageNames();
            }
        }, { immediate: true });

        // Function to load all images
        const loadCompressedImages = async () => {

            try {
                const client = new S3Client({
                    region: 'us-east-1', 
                    credentials: {
                        accessKeyId: credentials.value.accessKeyId,
                        secretAccessKey: credentials.value.secretAccessKey,
                        sessionToken: credentials.value.sessionToken,
                    },
                });
                const input = { // ListObjectsV2Request
                    Bucket: "ezra-pictures",
                    Prefix: "2024/compressed/"
                };
            
                const command = new ListObjectsV2Command(input);
                const response = await client.send(command);
            
                // Extract and log only the filenames
                const filenames = response.Contents.map(object => object.Key).filter(key => !key.endsWith('/')); // Filter out directory keys;
                imageFilenames.value = filenames;
                console.log(imageFilenames.value)
                pictures.value = filenames.map(file => `https://${input.Bucket}.s3.amazonaws.com/${file}`);

                // Retrieve the actual image data for each file
                const imagePromises = filenames.map(async (file) => {
                    const getObjectInput = {
                        Bucket: input.Bucket,
                        Key: file
                    };
                    const getObjectCommand = new GetObjectCommand(getObjectInput);
                    const objectResponse = await client.send(getObjectCommand);
                    
                    // Convert the image data to a blob URL
                    const blob = await objectResponse.Body.transformToByteArray();
                    return URL.createObjectURL(new Blob([blob]));
                });

                // Wait for all images to be loaded and set pictures.value
                pictures.value = await Promise.all(imagePromises);
            } catch (error) {
                // If there's an error, it indicates a problem with the credentials
                console.error('Invalid credentials:');
            }
        };

        const loadImageGroup = async(groupindex) => {
            if (usedChevrons.value.includes(groupindex)){
                const index = usedChevrons.value.indexOf(groupindex);
                if (index > -1) {
                    usedChevrons.value.splice(index, 1);
                }
            } else {
                usedChevrons.value.push(groupindex)
            }
            
            if (!loadedIndexes.value.includes(groupindex)) {
                loadedIndexes.value.push(groupindex)

                try {
                    const client = new S3Client({
                        region: 'us-east-1', 
                        credentials: {
                            accessKeyId: credentials.value.accessKeyId,
                            secretAccessKey: credentials.value.secretAccessKey,
                            sessionToken: credentials.value.sessionToken,
                        },
                    });

                    const input = { // ListObjectsV2Request
                        Bucket: "ezra-pictures",
                        Prefix: "2024/compressed/"
                    };

                    const filenames = groupedImages.value[groupindex].imageNames
                    pictures.value[groupindex] = (filenames.map(file => `https://${input.Bucket}.s3.amazonaws.com/${file}`));
                    // Retrieve the actual image data for each file
                    const imagePromises = filenames.map(async (file) => {
                        const getObjectInput = {
                            Bucket: input.Bucket,
                            Key: file
                        };
                        const getObjectCommand = new GetObjectCommand(getObjectInput);
                        const objectResponse = await client.send(getObjectCommand);

                        // Convert the image data to a blob URL
                        const blob = await objectResponse.Body.transformToByteArray();
                        return URL.createObjectURL(new Blob([blob]));
                    });
                    // Wait for all images to be loaded and set pictures.value
                    pictures.value[groupindex] = await Promise.all(imagePromises);
                    console.log("Number of Images: ", filenames.length)
                } catch (error) {
                    // If there's an error, it indicates a problem with the credentials
                    console.error('Invalid credentials:', error);
                }
            }
        }

        const loadImageNames = async () => {

            try {
                const client = new S3Client({
                    region: 'us-east-1', 
                    credentials: {
                        accessKeyId: credentials.value.accessKeyId,
                        secretAccessKey: credentials.value.secretAccessKey,
                        sessionToken: credentials.value.sessionToken,
                    },
                });
                const input = { // ListObjectsV2Request
                    Bucket: "ezra-pictures",
                    Prefix: "2024/compressed/"
                };
            
                const command = new ListObjectsV2Command(input);
                const response = await client.send(command);
            
                // Extract and log only the filenames
                const filenames = response.Contents.map(object => object.Key).filter(key => !key.endsWith('/')); // Filter out directory keys;
                imageFilenames.value = filenames;

                // await nextTick();
                groupImagesByTwoWeeks()

                
            } catch (error) {
                // If there's an error, it indicates a problem with the credentials
                console.error('Invalid credentials:');
            }
        };

        // group the images by date for lazy loading
        const groupedImages = ref([]);
        const groupImagesByTwoWeeks = () => {
            const groups = [];
            let currentGroup = [];
            let groupStartDate = null;
            let endDate = null;

            for (const file of imageFilenames.value) {
                const [year, , date] = file.split('/');
                const [month, day] = date.split('_').map(Number);
                const fileDate = new Date(year, month - 1, day);

                if (!groupStartDate) groupStartDate = fileDate;

                if ((fileDate - groupStartDate) / (1000 * 60 * 60 * 24) < 14) {
                    currentGroup.push(file);
                } else {
                    // console.log("Creating a new group");
                    endDate = new Date(groupStartDate);
                    endDate.setDate(endDate.getDate() + 13); 
                    groups.push({ 
                        imageNames: currentGroup, 
                        startDate: groupStartDate, 
                        endDate: endDate
                    });
                    
                    currentGroup = [file];
                    groupStartDate = new Date(endDate);
                    groupStartDate.setDate(groupStartDate.getDate() + 1); 
                }

            }
            
            if (currentGroup.length) {
                endDate = new Date(groupStartDate);
                endDate.setDate(endDate.getDate() + 13); 
                groups.push({ 
                    imageNames: currentGroup, 
                    startDate: groupStartDate, 
                    endDate:  endDate
                });
            }
            groupedImages.value = groups;

            for (let i = 0; i < groupedImages.value.length; i++) {
                pictures.value.push([]); // Adds an empty array as a blank element for each group
            }
            // console.log(groupedImages.value)
        };

        const formatDate = (date) => {
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const year = date.getFullYear();
            return `${month}/${day}/${year}`;
        };

    return {
        pictures,
        formatDate,
        loadImageGroup,
        imageFilenames,
        groupedImages,
        loadedIndexes,
        usedChevrons,
        loading,
        password,
        passwordVisible, 
        shake,
        credentials,
        handleFetchCredentials,
        loadImageNames,
        s3Files,
    };
  },
  
}
</script>

<style>
    @keyframes shake {
        0%, 100% { transform: translateX(0); }
        20%, 60% { transform: translateX(-5px); }
        40%, 80% { transform: translateX(5px); }
    }

    .animate-shake {
        animation: shake 0.5s ease-in-out;
    }
</style>