<template>
    <div class="px-5 md:px-10">
        <div class="font-bold text-lg text-left">{{job.title}} | {{ job.employer}}</div>
        <div class="italic text-left text-base"> {{ job.dates }}</div>
        <div class="text-left text-base">{{ job.details }}</div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ExperienceCard',
    props: {
        job: {
            type: Object,
            required: true,
        }
    },
});
</script>

<style>
</style>