import { createRouter, createWebHistory } from 'vue-router'
import WelcomeView from '../views/WelcomeView.vue'
import FilesView from '../views/FilesView.vue'
import ResearchView from '../views/ResearchView.vue'
import ResumeView from '../views/ResumeView.vue'
import CatsView from '../views/CatsView.vue'
import ProjectView from '../views/ProjectView.vue'
import EzraView from '../views/EzraView.vue'

const routes = [
  {
    path: '/',
    name: 'welcome',
    component: WelcomeView
  },
  

  {
    path: '/files',
    name: 'files',
    component: FilesView
  },
  {
    path: '/research',
    name: 'research',
    component: ResearchView
  },
  {
    path: '/projects',
    name: 'projects',
    component: ProjectView
  },
  {
    path: '/resume',
    name: 'resume',
    component: ResumeView
  },
  {
    path: '/cats',
    name: 'cats',
    component: CatsView
  },
  {
    path: '/ezra',
    name: 'ezra',
    component: EzraView
  },
  //catch all 404
  {
    path: '/:catchAll(.*)',
    name: 'notFound',
    component: WelcomeView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
