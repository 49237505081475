<template>
    <div class="px-5 md:px-10">
        <div class="font-bold text-lg text-left">{{project.title}}</div>
        <div class="text-left text-base">{{ project.details }}</div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ProjectCard',
    props: {
        project: {
            type: Object,
            required: true,
        }
    },
});
</script>

<style>
</style>