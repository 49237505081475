<template>
     <div class="bg-darkpurple h-full">
        <div class="flex flex-wrap flex-row gap-8 justify-center p-8 mx-auto max-w-screen-xl">
            <div class="bg-primarybackground rounded-3xl text-primarytext p-4 h-fit border-solid border-2 border-primarytext">
                <h1 class="text-4xl font-bold p-8 pb-2">Hello There!</h1>
                <div class="flex justify-center w-full p-8 pt-2">
                    <p class="max-w-xl"> 
                        My name is Isaac Rudich. I recently finished a PhD in Operations Research at Polytechnique Montréal, and I am looking for interesting problems to solve. If you want to know about my professional life, check out my <router-link class="link" :to="{name:research_address}">research and publications</router-link>,  <router-link class="link" :to="{name:projects_address}">project history</router-link>, or <router-link class="link" :to="{name:cv_address}">resume</router-link>.
                    </p>
                </div>
                <div class="flex justify-center w-full">
                    <img :src="picture_of_me" alt="Missing Image" class="object-cover rounded-full w-48 h-48 md:w-64 md:h-64 lg:w-96 lg:h-96">
                </div>
                <div class="justify-center w-full p-8">
                        <p class="max-w-xl" v-if="false">
                            If you want to learn something instead, try playing my silly word game: <router-link class="link" :to="{name:word_game_address}">Word Fabble</router-link>.
                        </p>
                        <p class="max-w-xl">
                            I made some <router-link class="link" :to="{name:files_address}">animated decision diagrams</router-link> that are available for use in talks or lectures. You should also check out my adorable <router-link class="link" :to="{name:cats_address}">cats</router-link>.
                        </p>
                        <p>
                            If you want to reach me, use my email: isaac.rudich at gmail.com
                        </p>
                </div>
            </div>  
        </div> 
    </div>
</template>

<script>
import { require } from 'vue';

export default {
    name: 'WelcomeView',
    data() {
        return {
            picture_of_me:  require('@/../public/images/isaac/netherlands_beach.webp'),
            cv_address:"resume",
            research_address:"research",
            word_game_address:"wordfabble",
            projects_address:"projects",
            cats_address:"cats",
            files_address:"files",
        };
    },
}
</script>

<style>
    .link{
        @apply text-blue-400 underline hover:text-blue-600 focus:outline-none focus:text-blue-600;
    }
</style>