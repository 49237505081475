<template>
     <!-- <div class="bg-no-repeat bg-center bg-cover" :style="{ backgroundImage: `url(${forest_background})` }"> -->
    <div class="bg-darkpurple">
        <div class="flex flex-wrap flex-row gap-8 justify-center p-8 mx-auto max-w-screen-xl">
            <div v-if="isDataLoaded" class="bg-primarybackground rounded-3xl text-primarytext p-4 h-fit border-solid border-2 border-primarytext">
                <div class="p-4 md:p-8 pb-8">
                    <h1 class="text-2xl font-bold text-left">Overview</h1>
                    <div class="text-left px-1 md:px-2 text-lg mt-2">
                        <div class="mb-2">
                            This section is intended for those with a math background. If you would prefer a jargon-free explanation, and real-world examples, see my <router-link class="link" :to="{name:projects_address}">projects</router-link> instead.
                        </div>
                        My research focuses on improving methods of solving combinatorial optimization problems, and applying those methods to real world problems. 
                        Much of my research focuses on decision diagrams.
                        Decision diagrams are a graphical representation of the solution space for combinatorial problems. The use of decision diagrams (DDs) for optimization is a relatively new field, but other approaches for solving combinatorial optimization problems have been refined for decades. 
                        I integrate DD-based methods with conventional methods to get results that neither approach could achieve independently.
                        DD-based methods are amenable to real-world problems that are heavily constrained or hard to model using traditional methods, so DD-based methods can help to move the cutting edge on real-world problems.
                        The overarching goal of my research is to create state-of-the-art optimization algorithms that can be applied to real-world problems with a high humanitarian impact.
                    </div>
                </div>
                <h1 class="text-2xl font-bold pl-4 md:pl-8 text-left">Publications</h1>
                <PublicationCard 
                    v-for="publication in publications" 
                    :key="publication.id" 
                    :publication="publication" 
                    :isAbstractVisible="visibleAbstractId === publication.id" 
                    @toggleAbstract="toggleAbstractVisibility(publication.id)"
                    :isBibTexVisible="visibleBibTexId === publication.id" 
                    @toggleBibTex="toggleBibTexVisibility(publication.id)"
                />
            </div>
        </div>
     </div>
</template>

<script>
import { ref, onMounted, require } from 'vue';
import publicationData from "@/data/publications.json";
import PublicationCard from '@/components/PublicationCard.vue'

export default {
    name: 'ResearchView',
    components: {
        PublicationCard,
    },
    data() {
        return {
            forest_background:  require('@/../public/images/backgrounds/paper_quill_2.webp'),
            projects_address:"projects",
        };
    },
    setup(){
        const isDataLoaded = ref(false);
        const publications = ref([]);
        const visibleAbstractId = ref(null);
        const visibleBibTexId = ref(null);

        const processPublicationData = async () => {
            publicationData.publications.map(async (publication) => {

                publications.value = [...publications.value, publication];
            });
            publications.value = publications.value.reverse();
            isDataLoaded.value = true;
        };

        onMounted(() => {
            processPublicationData();
        });

        // Function to toggle the visibility
        const toggleAbstractVisibility = (id) => {
            visibleAbstractId.value = visibleAbstractId.value === id ? null : id;
            visibleBibTexId.value = null;
        };

        const toggleBibTexVisibility = (id) => {
            visibleBibTexId.value = visibleBibTexId.value === id ? null : id;
            visibleAbstractId.value = null;
        };

        return { publications, isDataLoaded, toggleAbstractVisibility, visibleAbstractId, toggleBibTexVisibility, visibleBibTexId };
    },
    methods: {
        formatBibtex(bibtex) {
            return bibtex.replace(/\n/g, '<br>');
        }
    }
}
</script>

<style>

</style>