<template>
    <div class="nav-container"><!-- nav -->
        <nav class="text-left p-0">
            <div class="flex justify-between items-center sm:justify-center sm:pb-2">
                <!-- logo -->
                <div class="flex-grow sm:flex-grow-0 sm:justify-center">
                    <h1 class="font-bold uppercase p-4 sm:border-b border-gray-100">
                        <a href="/" class="flex justify-start">
                            <div class="bg-primarytext w-12 h-12 sm:w-24 sm:h-auto rounded-lg flex items-center justify-center">
                                <img :src="logoUrl" alt="logo" class="p-2 w-20 h-auto">
                            </div>
                        </a>
                    </h1>
                </div>
                <!-- hamburger -->
                <div @click="showSections = !showSections" class="px-4 cursor-pointer sm:hidden " id="burger">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                </div>
            </div>

            <div v-if="showSections" class="md:hidden pb-2">
                <div class="nav-bar-section" v-for="section in sections" :key="section">
                    <NavBarSection :sectiontext="section.sectiontext" :svgpath="section.svgpath" :address="section.address" :sWidth="section.sWidth"/>
                </div>
            </div>
            <div class="hidden md:block">
                <div class="nav-bar-section" v-for="section in sections" :key="section">
                    <NavBarSection :sectiontext="section.sectiontext" :svgpath="section.svgpath" :address="section.address" :sWidth="section.sWidth"/>
                </div>
            </div>
        </nav>
    </div><!-- end nav -->
</template>

<script>
    import NavBarSection from './NavBarSection.vue'
    export default {
        components: { NavBarSection },
        data() {
            return {
                showSections: true,
                logoUrl: require('@/assets/logo.png')
            };
        },
        setup(){
            const sections = [
                {sWidth:2, sectiontext:"Welcome!", address:"welcome",svgpath:"M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"},
                {sWidth:2, sectiontext:"Research & Publications", address:"research",svgpath:"M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"},
                {sWidth:2, sectiontext:"Project History", address:"projects",svgpath:"M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z"},
                {sWidth:2, sectiontext:"Resume", address:"resume",svgpath:"M4.26 10.147a60.438 60.438 0 0 0-.491 6.347A48.62 48.62 0 0 1 12 20.904a48.62 48.62 0 0 1 8.232-4.41 60.46 60.46 0 0 0-.491-6.347m-15.482 0a50.636 50.636 0 0 0-2.658-.813A59.906 59.906 0 0 1 12 3.493a59.903 59.903 0 0 1 10.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.717 50.717 0 0 1 12 13.489a50.702 50.702 0 0 1 7.74-3.342M6.75 15a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm0 0v-3.675A55.378 55.378 0 0 1 12 8.443m-7.007 11.55A5.981 5.981 0 0 0 6.75 15.75v-1.5"},
                {sWidth:1.2, sectiontext:"Cat Pictures", address:"cats",svgpath:"M 21.6948 0.8775 L 20.058 3.5833 c -1.4073 -0.456 -3.5017 -0.3669 -4.8196 0 l -1.5762 -2.668 c -1.6622 5.0847 -1.6622 8.0847 0.1195 9.412 c -0.3019 0.189 -0.6137 0.3968 -0.9354 0.6244 c -8.2344 -6.5694 -15.6471 0.8297 -10.7841 7.4403 h 0.0373 c 0.8726 1.2653 1.8774 2.048 2.9629 2.4576 c 1.5374 0.5801 3.1193 0.413 4.677 0.1925 s 3.082 -0.4974 4.5347 -0.3939 c 1.4527 0.1034 2.9057 0.4764 4.2354 1.9264 c 0.9067 0.9886 2.1943 -0.0761 1.1148 -1.1304 c -1.6391 -1.6008 -3.3941 -2.2192 -5.1187 -2.3418 s -3.3788 0.2198 -4.8767 0.4318 s -2.8208 0.3066 -3.9573 -0.1222 c -0.533 -0.2011 -1.0521 -0.5176 -2.6716 -2.412 h 16.8822 C 21.7992 15.5751 21.8839 12.5946 20.9766 10.7145 c 2.0234 -1.7145 2.0234 -4.7145 0.7183 -9.837 z z z"},
                {sWidth:2, sectiontext:"Useful Files", address:"files",svgpath:"M16.5 3.75V16.5L12 14.25 7.5 16.5V3.75m9 0H18A2.25 2.25 0 0120.25 6v12A2.25 2.25 0 0118 20.25H6A2.25 2.25 0 013.75 18V6A2.25 2.25 0 016 3.75h1.5m9 0h-9"}
            ]
            return {sections}
        }
    }   
</script>

<style>
    nav {
        @apply p-5 sm:w-68;
    }

    .router-link-active {
        @apply border-backgroundblue text-backgroundblue
    }

    .nav-container {
        @apply sm:col-span-1 sm:flex sm:justify-center bg-primarybackground sm:w-72 h-full;
    }

    .nav-bar-section {
        @apply text-secondarytext font-bold py-1 hover:text-primarytext hover:text-lg transition ease-in-out duration-300;
    }
</style>