<template>
    <div class="bg-darkpurple mt-8">
        <div class="flex flex-col items-center">
            <GroupedFilesCard  v-for="file_set in groupedFiles" :key="file_set.id" :file_set="file_set" />
            <FilesCard v-for="file_set in files" :key="file_set.id" :file_set="file_set" />
        </div>
    </div>
</template>

<script>
import { ref, require, onMounted } from 'vue';
import filesData from "@/data/downloadables.json";
import FilesCard from '@/components/FilesCard.vue'
import groupedFilesData from "@/data/grouped_downloadables.json";
import GroupedFilesCard from '@/components/GroupedFilesCard.vue'

export default {
    name: 'FilesView',
    components: {
        FilesCard,
        GroupedFilesCard
    },
    setup(){
        const isDataLoaded = ref(false);
        const files = ref([]);
        const groupedFiles = ref([])

        const processFilesData = async () => {
            groupedFiles.value = groupedFilesData.grouped_files
            files.value = filesData.files
            isDataLoaded.value = true

            // console.log(groupedFiles.value)
            // console.log(files.value)
        };

        onMounted(() => {
            processFilesData();
        });

        return { files, groupedFiles, isDataLoaded };
    }
}
</script>

<style>

</style>






