<template>
    <div class="px-5 md:px-10 pt-4">
        <div class="font-bold text-lg text-left">{{publication.title}}</div>
        <div class="italic text-left text-base">{{ publication.publisher }}, {{ publication.pub_details }}, {{ publication.year }}</div>
        <div class="text-left text-base" v-html="publication.authors"></div>
    
        <div class="flex flex-row justify-start items-center mb-4">
            <button class="px-2 py-0 bg-purple-700 text-white text-sm rounded shadow mr-2 hover:bg-purple-800 active:bg-purple-900 focus:outline-none transition duration-150 ease-in-out" @click="toggleAbstractVisibility">Abstract</button>
            <button class="px-2 py-0 bg-purple-700 text-white text-sm rounded shadow mr-2 hover:bg-purple-800 active:bg-purple-900 focus:outline-none transition duration-150 ease-in-out" @click="toggleBibTexVisibility">BibTeX</button>
            <a :href="publication.paper_link" target="_blank" class="px-2 py-0 bg-purple-700 text-white text-sm rounded shadow hover:bg-purple-800 active:bg-purple-900 focus:outline-none transition duration-150 ease-in-out">Go to Paper</a>
        </div>

        <div class="flex flex-col justify-center items-center"  v-if="showBibTex">
            <div class="max-w-56 md:max-w-xs lg:max-w-sm bg-primarytext  text-primarybackground overflow-auto rounded-lg p-2 md:p-4 relative">
                <pre class="text-xs text-left whitespace-pre overflow-x-auto" v-html="publication.bibtex"></pre>
                <button class="absolute bottom-2 right-2 px-3 py-1 bg-purple-600 text-white text-sm rounded shadow hover:bg-purple-700 active:bg-purple-800 focus:outline-none transition duration-150 ease-in-out" @click="copyToClipboard(publication.bibtex)">Copy to Clipboard</button>
                <div v-if="showCopiedMessage" class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-purple-200 text-purple-800 px-4 py-2 rounded shadow">
                    Copied to clipboard
                </div>
            </div>
        </div>

        <div v-if="showAbstract" class="p-4 bg-gray-300 rounded-lg mt-4">
            <div class="text-left text-sm md:text-base text-black">{{ publication.abstract }}</div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'PublicationCard',
    props: {
        publication: {
            type: Object,
            required: true,
        },
        isAbstractVisible: {
            type: Boolean,
            default: false,
        },
        isBibTexVisible: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['toggleAbstract', 'toggleBibTex'],
    methods: {
        copyToClipboard(text) {
            navigator.clipboard.writeText(text).then(() => {
                // Handle successful copy
                this.showCopiedMessage = true;
                setTimeout(() => this.showCopiedMessage = false, 2000); // Message disappears after 2 seconds
            }, (err) => {
                // Handle error
                console.error("Failed to copy: ", err);
            });
        },
        toggleBibTexVisibility() {
            this.$emit('toggleBibTex'); 
        },
        toggleAbstractVisibility() {
            this.$emit('toggleAbstract'); 
        },
    },
    
    data() {
        return {
            showCopiedMessage: false,
            showBibTex: this.isBibTexVisible,
            showAbstract: this.isAsbtractVisible,
        };
    },
    watch: {
        isAbstractVisible(newValue) {
            this.showAbstract = newValue;
        },
        isBibTexVisible(newValue) {
            this.showBibTex = newValue;
        }
    }
});
</script>

<style>
</style>