<template>
    <div class="bg-darkpurple h-full">
        <div class="bg-verydarkpurple text-primarytext text-center py-4 border-b-2 border-t-2 border-primarytext">
            <p>The gray cat is Sqwonk, he is a brainless goofball. <br class="xl:hidden"> The black cat is Pizazz, she is adorable and she knows it.</p>
        </div>

        <div class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-8 m-8">
            <div v-for="(cat, index) in pictures" :key="index" class="flex items-center justify-center">
                <img :src="cat" alt="Missing Image" class="object-cover rounded-3xl w-full h-auto">
            </div>
        </div>
    </div>
</template>
<script>
import { ref, onMounted, require } from 'vue';


export default {
    name: 'CatsView',
    setup(){
        const basePath = 'images/cats/compressed'
        const pictures = ref([]);

        for (let i = 1; i <= 88; i++) {
            pictures.value.push(`${basePath}/cat_${i}.webp`);
        }

        // Shuffle function - Fisher-Yates (Knuth) Shuffle
        function shuffle(array) {
            let currentIndex = array.length, temporaryValue, randomIndex;

            // While there remain elements to shuffle...
            while (0 !== currentIndex) {
                // Pick a remaining element...
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex -= 1;

                // And swap it with the current element.
                temporaryValue = array[currentIndex];
                array[currentIndex] = array[randomIndex];
                array[randomIndex] = temporaryValue;
            }

            return array;
        }

        // Shuffle the pictures array
        shuffle(pictures.value);

        return {
            pictures,
        };

        
    }
}
</script>

<style>

</style>