<template>
    <div class="bg-primarybackground rounded-lg text-primarytext p-4 max-w-xl min-w-sm h-fit overflow-x-hidden w-full border-primarytext, border-2 mb-8">
        <div class="flex flex-col items-center">
            <div @click="downloadFile(file_set.file)" class="group w-full mb-2 hover:cursor-pointer hover:font-bold transition ease-in-out duration-300 flex items-center">
                    <div class="mx-auto flex items-center">
                        <div class="text-xl font-bold">{{file_set.name}}</div>
                        <div class="bg-primarytext rounded-lg inline-flex items-center group-hover:border-2 group-hover:border-backgroundblue ml-2 h-6">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-4 inline text-primarybackground">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                            </svg>
                        </div>
                    </div>
            </div>
            <div class="text-primarytext">{{ file_set.description }}</div>
        </div>
        
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'FilesCard',
    props: {
        file_set: {
            type: Object,
            required: true
        },
    },
    methods: {
        downloadFile(url) {
            console.log(url)
            const link = document.createElement("a");
            link.href = url;
            console.log(link.href)
            link.download = "";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
});
</script>

<style>
</style>