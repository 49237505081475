<template>
  <div class="min-h-screen overflow-hidden max-w-full">
    <div class="grid h-full sm:grid-cols-[288px_1fr] min-h-screen"><!-- content wrapper -->
      <NavBar/>
      <router-view class="sm:col-span-1"/>
    </div>
  </div>
</template>

<script>
import { useRoute } from 'vue-router';
import NavBar from './components/NavBar.vue'

export default {
  name: 'App',
  components: {
    NavBar
  },
  mounted() {
    document.title = "Isaac R.";
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

* {
  margin: 0;
  padding: 0;
}

.app-grid {
  grid-template-columns: min-content auto
}

html {
    @apply bg-darkpurple; /* Or any other color you prefer */
}

</style>
